import React, { useEffect, useState } from 'react';
import he from 'he';
import { useParams } from 'react-router-dom';
import alertify from 'alertifyjs';

import { scryRenderedComponentsWithType } from 'react-dom/test-utils';
import Left from './Left';
import Right from './Right';
const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});



function opComment(id) {
    var cArea = document.getElementById('comment' + id);
    if (cArea.style.display == "block") {
        cArea.style.display = "none";
    } else {
        cArea.style.display = "block";
    }
}
function UserDetail( {langData} ) {
    let userid = localStorage.getItem('userid');
    let dil = localStorage.getItem('dil');
    let stockimg = localStorage.getItem('stockimg');
    let localDocs = localStorage.getItem('localDocs');
    let { id } = useParams();
    const [getProfile, setGetProfile] = useState([]);
    const [getPosts, setGetPosts] = useState([]);
    const fetchGetProfile = () => {
        fetch(localDocs+'/rn-adaptor/l-userProfile?id=' + id + "&userid=" + userid)
            .then(responseGP => responseGP.json())
            .then(getProfile => setGetProfile(getProfile))
            .catch(errorGP => console.error('Fetch error : ', errorGP));
    }
    const fetchGetPosts = () => {
        fetch(localDocs+'/rn-adaptor/l-userPosts?id=' + id + '&userid=' + userid)
            .then(responseGPo => responseGPo.json())
            .then(getPosts => setGetPosts(getPosts))
            .catch(errorGPo => console.error('Fetch error : ', errorGPo));
    }
    console.log(getPosts);
    useEffect(() => {
        fetchGetProfile();
        fetchGetPosts();
    }, []);
    
    const veriler = getProfile[0];
    const parser = new DOMParser();

    const followUser = async (userid, otherid, dil) => {
        const data = { userid: userid, otherid: otherid, dil: dil };
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-followUser.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const responseJson = await response.json();
            console.log("PHP tarafından dönen yanıt:", responseJson);
            if (responseJson.status == 0) { alertify.error(responseJson.message, 2); document.getElementById('followbtn').innerHTML = responseJson.btn; }
            if (responseJson.status == 1) { alertify.success(responseJson.message, 2); document.getElementById('followbtn').innerHTML = responseJson.btn; }
            fetchGetPosts();
        } catch (error) { console.log("An error :", error); }
    };
    const postLike = async (userid, postid, dil) => {
        const data = { userid: userid, postid: postid, dil: dil };
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-postLike.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const plJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", plJson);
            fetchGetPosts();
        } catch (error) { console.log("An error :", error); }
    };
    const postBook = async (userid, postid, dil) => {
        const data = { userid: userid, postid: postid, dil: dil };
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-addBookmarkt.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const pbJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", pbJson);
            fetchGetPosts();
        } catch (error) { console.log("An error :", error); }
    };

    const subscribeUser = async (userid, otherid, dil, month, subs1, subs2, subs3, subs6, subs12) => {
        const data = { userid: userid, otherid: otherid, dil: dil, month: month };
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-subscribeUser.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });

            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const rJson = await response.json();
            console.log("PHP tarafından dönen yanıt:", rJson);
            if (rJson.status == 0) {
                alertify.error(rJson.message, 2);
                if (rJson.month == 1) { document.getElementById('subbtn1').innerHTML = rJson.btn1; document.getElementById('subsbtn1').innerHTML = rJson.btn2; }
                if (rJson.month == 2) { document.getElementById('subbtn2').innerHTML = rJson.btn1; document.getElementById('subsbtn2').innerHTML = rJson.btn2; }
                if (rJson.month == 3) { document.getElementById('subbtn3').innerHTML = rJson.btn1; document.getElementById('subsbtn3').innerHTML = rJson.btn2; }
                if (rJson.month == 6) { document.getElementById('subbtn6').innerHTML = rJson.btn1; document.getElementById('subsbtn6').innerHTML = rJson.btn2; }
                if (rJson.month == 12) { document.getElementById('subbtn12').innerHTML = rJson.btn1; document.getElementById('subsbtn12').innerHTML = rJson.btn2; }
                if (rJson.month != 1) { if (subs1 > 0) { document.getElementById('mmbfs1').style.display = "none"; } }
                if (rJson.month != 2) { if (subs2 > 0) { document.getElementById('mmbfs2').style.display = "none"; } }
                if (rJson.month != 3) { if (subs3 > 0) { document.getElementById('mmbfs3').style.display = "none"; } }
                if (rJson.month != 6) { if (subs6 > 0) { document.getElementById('mmbfs6').style.display = "none"; } }
                if (rJson.month != 12) { if (subs12 > 0) { document.getElementById('mmbfs12').style.display = "none"; } }
            }
            if (rJson.status == 1) {
                alertify.success(rJson.message, 2);
                if (rJson.month == 1) { document.getElementById('subbtn1').innerHTML = rJson.btn1; document.getElementById('subsbtn1').innerHTML = rJson.btn2; }
                if (rJson.month == 2) { document.getElementById('subbtn2').innerHTML = rJson.btn1; document.getElementById('subsbtn2').innerHTML = rJson.btn2; }
                if (rJson.month == 3) { document.getElementById('subbtn3').innerHTML = rJson.btn1; document.getElementById('subsbtn3').innerHTML = rJson.btn2; }
                if (rJson.month == 6) { document.getElementById('subbtn6').innerHTML = rJson.btn1; document.getElementById('subsbtn6').innerHTML = rJson.btn2; }
                if (rJson.month == 12) { document.getElementById('subbtn12').innerHTML = rJson.btn1; document.getElementById('subsbtn12').innerHTML = rJson.btn2; }
                if (rJson.month != 1) { if (subs1 > 0) { document.getElementById('mmbfs1').style.display = "none"; } }
                if (rJson.month != 2) { if (subs2 > 0) { document.getElementById('mmbfs2').style.display = "none"; } }
                if (rJson.month != 3) { if (subs3 > 0) { document.getElementById('mmbfs3').style.display = "none"; } }
                if (rJson.month != 6) { if (subs6 > 0) { document.getElementById('mmbfs6').style.display = "none"; } }
                if (rJson.month != 12) { if (subs12 > 0) { document.getElementById('mmbfs12').style.display = "none"; } }
            }
            if (rJson.status == 2) { alertify.success(rJson.message, 2); }
            fetchGetPosts();
        } catch (error) { console.log("An error :", error); }
    };
    const [text, setText] = useState([]);
    const emojis = ["😀", "😁", "😅", "😄", "😂", "🤣", "🥰", "😍", "💑", "😎", "🤔", "😠", "😞", "😪", "😭", "🎈"];

    const addEmoji = (emoji) => {
        setText(text + emoji);
    }
    const textChange = (event) => {
        setText(event.target.value);
    }
    const sendComment = async (userid, postid, dil) => {
        const tArea = document.getElementById('commentText' + postid).value;
        const data = { userid: userid, postid: postid, dil: dil, comment: tArea };
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-sendComment.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const plJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", plJson);
            fetchGetPosts();
            alertify.success(plJson.message, 2);
            document.getElementById('commentText' + postid).value = "";
            setText('');
        } catch (error) { console.log("An error :", error); }
    };
    const sendTip = async (userid, dil) => {
        const tipArea = document.getElementById('tipAmount').value;
        const tipComment = document.getElementById('tipComment').value;
        const tipUser = document.getElementById('mtAreaUser').innerHTML;
        const tipPost = document.getElementById('mtAreaPost').innerHTML;
        const data = { userid: userid, dil: dil, tip: tipArea, comment: tipComment, ouser: tipUser, postid: tipPost };
        //console.log(data);
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-sendTip.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const plJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", plJson);
            fetchGetPosts();
            document.getElementById('modalTip').style.display = "none";
            document.getElementById('tipAmount').value = "";
            document.getElementById('tipComment').value = "";
            if (plJson.status == 0) { alertify.error(plJson.message, 2); }
            if (plJson.status == 1) { alertify.success(plJson.message, 2); }

        } catch (error) { console.log("An error :", error); }
    };
    let lastGid = "all";
    function chMamTab(gid){
        let videoClass = document.getElementsByClassName('mamVideo');
        let imageClass = document.getElementsByClassName('mamImage');
        if(gid == "all"){
            for(var i = 0; i < imageClass.length; i++) {imageClass[i].style.display = "block";}
            for(var i = 0; i < videoClass.length; i++) {videoClass[i].style.display = "block";}
            lastGid = gid;
        }
        if(gid == "videos"){
            for(var i = 0; i < imageClass.length; i++) {imageClass[i].style.display = "none";}
            for(var i = 0; i < videoClass.length; i++) {videoClass[i].style.display = "block";}
            lastGid = gid;
        }
        if(gid == "images"){
            for(var i = 0; i < imageClass.length; i++) {imageClass[i].style.display = "block";}
            for(var i = 0; i < videoClass.length; i++) {videoClass[i].style.display = "none";}
            lastGid = gid;
        }
    }
    let latestID = "";
    let latestTip = "";
    let latestMtip = "";
    let latestOtherId = "";
    const openMedia = async (pid, userid, tip, dil, mtip, otherid, gid) => {
        const data = {pid : pid, userid: userid, tip: tip, dil: dil, mtip: mtip, otherid: otherid, gid: gid};
        latestID = pid;
        latestTip = tip;
        latestMtip = mtip;
        latestOtherId = otherid;
        //latestRoad = road;
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-getMedia.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); } else {
                const gtJson = await response.json();
                //console.log(gtJson);
                if(gtJson.road){document.getElementById('modalMedia').style.display = "flex";}else{document.getElementById('modalMedia').style.display = "none";}
                if(gtJson.canPrev == "1"){document.getElementById('mmDatasLeft').style.display="block";}else{document.getElementById('mmDatasLeft').style.display="none";}
                if(gtJson.canNext == "1"){document.getElementById('mmDatasRight').style.display="block";}else{document.getElementById('mmDatasRight').style.display="none";}
                latestID = gtJson.pid;
                latestTip = gtJson.tip;
                latestMtip = gtJson.mtip;
                latestOtherId = gtJson.otherid;
                if(gtJson.dtip == "video"){
                    document.getElementById('mmDatasImage').style.display="none";
                    document.getElementById('mmDatasVideo').style.display="flex";
                    document.getElementById('modalVideo').src = gtJson.road;
                }else{
                    document.getElementById('mmDatasImage').style.display="flex";
                    document.getElementById('mmDatasVideo').style.display="none";
                    document.getElementById('modalImage').src = gtJson.road;
                }
                
            }

        } catch (error) { console.log("An error :", error); }
    };
    const nextMedia = () => {openMedia(latestID, userid, 'next', dil, latestMtip, latestOtherId, lastGid); }
    const prevMedia = () => {openMedia(latestID, userid, 'prev', dil, latestMtip, latestOtherId, lastGid); }
    const getTip = async (userid, postid) => {
        const data = { userid: userid };
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-getTip.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const gtJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", gtJson);
            document.getElementById('modalTip').style.display = "flex";
            document.getElementById('mtAreaImg').src = gtJson.pp;
            document.getElementById('mtAreaP').innerHTML = gtJson.name + ' ' + gtJson.surname;
            document.getElementById('mtAreaA').href = '/user-detail' + gtJson.userid;
            document.getElementById('mtAreaA').innerHTML = '@' + gtJson.username;
            document.getElementById('mtAreaUser').innerHTML = userid;
            document.getElementById('mtAreaPost').innerHTML = postid;
        } catch (error) { console.log("An error :", error); }
    };
    const buyPost = async (userid, postid, dil) => {
        const data = { userid: userid, postid: postid, dil: dil }
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-buyPost.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error('HTTP error! status : ${response.status}'); }
            const bpJson = await response.json();
            if (bpJson.status == 0) { alertify.error(bpJson.message, 2); }
            if (bpJson.status == 1) { alertify.success(bpJson.message, 2); }
            fetchGetPosts();
            console.log(bpJson);
        } catch (error) { console.log("An error : ", error); }
    }
    function mClose(){
        document.getElementById('modalMedia').style.display = "none";
        document.querySelector("#modalVideo").pause();
    }
    function copyLink(){
        navigator.clipboard.writeText(window.location.href);
        alertify.success("Link copied.", 2);
        console.log(window.location.href);
    }
    //console.log(getPosts);
    //document.getElementById('posts125').innerHTML = "asd";
    let latestPfm = "0";
    function opPfm(gPfmId){
        if(latestPfm != "0"){document.getElementById(latestPfm).style.display = "none";}
        if(latestPfm != gPfmId){document.getElementById(gPfmId).style.display = "block";latestPfm = gPfmId;}else{latestPfm = "0";}
    }
    function opReport(rUserid, rType, rPostid, rpostTitle){
        console.log(rUserid + ' - '+ rType + ' - ' + rPostid + ' - '+rpostTitle);
        document.getElementById('reportType').innerHTML = rType;
        document.getElementById('reportUser').innerHTML = rUserid;
        document.getElementById('reportPost').innerHTML = rPostid;
        document.getElementById('reportPostTitle').innerHTML = 'Post : '+(parser.parseFromString(he.decode(rpostTitle), 'text/html')).documentElement.textContent;
        document.getElementById('reportForm').style.display = 'flex';
        document.getElementById('reportMessage').value="";
        console.log('opRep');
    }
    const sendReport = (userid, lang) => {
        let repMsg = document.getElementById('reportMessage').value;
        let repUser = document.getElementById('reportUser').innerHTML;
        let repPost = document.getElementById('reportPost').innerHTML;
        console.log(userid + ' - '+ repUser + ' - ' + repPost + ' - '+ repMsg);
        const formData = new FormData();
        formData.append('userid', userid); formData.append('msgid', repPost); formData.append('repUser', repUser); formData.append('msg', repMsg);
        fetch(localDocs+'/rn-adaptor/functions.php?islem=repPost', { method: 'POST', body: formData }).
        then(response => {
            if (response.status == 200) { alertify.success('Your report sended.', 2); }
            document.getElementById('reportForm').style.display = "none";
        })
        .catch(error => { console.log(error); });
    };
    return (
        <div>
            <div className='leftAreaC'>
                <Left title="Title" />
            </div>
            <div className='fullAreaC'>
                <div key={id} className='midAreaC userProfile'>
                    <div id="modalTip" className="modalTip">
                        <a onClick={() => document.getElementById('modalTip').style.display = "none"} className='mmClose'></a>
                        <div className='mtArea'>
                            <h5>{langData.send_tip}</h5>
                            <span onClick={() => document.getElementById('modalTip').style.display = "none"}>x</span>
                            <div className='mtUserArea'>
                                <img id="mtAreaImg" src={stockimg} />
                                <p id="mtAreaP">Name Surname</p>
                                <a href={'/user-detail/1'} id='mtAreaA'>@username</a>
                            </div>
                            <p id='mtAreaUser'>0</p>
                            <p id='mtAreaPost'>0</p>
                            <div className='mtAmountArea'>
                                <p>{langData.amount}</p>
                                <img src={require('../assets/icons/tip.webp')} />
                                <input id="tipAmount" placeholder={langData.amount} />
                            </div>
                            <div className='mtDescArea'>
                                <p>{langData.message}</p>
                                <textarea id='tipComment' placeholder={langData.message}></textarea>
                            </div>
                            <div className='mtButtonArea'>
                                <a onClick={() => document.getElementById('modalTip').style.display = "none"}>{langData.cancel}</a>
                                <a onClick={() => sendTip(userid, dil)}>{langData.send_tip}</a>
                            </div>
                        </div>
                    </div>
                    <div id="reportForm" className="reportForm">
                        <a onClick={() => document.getElementById('reportForm').style.display = "none"} className='mmClose'></a>
                        <div className='mtArea'>
                            <h5>{langData.report_form}</h5>
                            <span onClick={() => document.getElementById('reportForm').style.display = "none"}>x</span>
                            <div className='mtUserArea'>
                                <p id="reportPostTitle">Post Title</p>
                            </div>
                            <p style={{display : "none"}} id='reportType'>0</p>
                            <p style={{display : "none"}} id='reportUser'>0</p>
                            <p style={{display : "none"}} id='reportPost'>0</p>
                            <div style={{display: "none"}} className='mtAmountArea'>
                                <p>{langData.title}</p>
                                <img src={require('../assets/icons/tip.webp')} />
                                <input id="reportTitle" placeholder={langData.title} />
                            </div>
                            <div className='mtDescArea'>
                                <p>{langData.message}</p>
                                <textarea id='reportMessage' placeholder={langData.message}></textarea>
                            </div>
                            <div className='mtButtonArea'>
                                <a onClick={() => document.getElementById('reportForm').style.display = "none"}>{langData.cancel}</a>
                                <a onClick={() => sendReport(userid, dil)}>{langData.send}</a>
                            </div>
                        </div>
                    </div>
                    <div id='modalMedia' className='modalMedia'>
                        <a onClick={() => mClose()} className='mmClose'></a>
                        <div className='mmDatas'>
                            <a onClick={() => prevMedia()} className='mmDatasLeft' id='mmDatasLeft'><img src={require('../assets/icons/arrow.webp')} /></a>
                            <div className='mmDatasImage' id='mmDatasImage'>
                                <img id='modalImage' src={stockimg} />
                            </div>
                            <div className='mmDatasVideo' id='mmDatasVideo'>
                                <video id='modalVideo' controls><source id='modalVideoSrc' src={stockimg} type="video/mp4" /></video>
                            </div>
                            <a onClick={() => nextMedia()} className='mmDatasRight' id='mmDatasRight'><img src={require('../assets/icons/arrow.webp')} /></a>
                        </div>
                    </div>
                    {veriler ? (
                        <div>
                            <div className='upUserTop' style={{ background: `url(${veriler.background})`, }}>
                                <div className='upUserTopCover'></div>
                                <a onClick={()=>window.history.back()} className='leftArrow'><img src={require('../assets/icons/leftarrow.webp')} /></a>
                                <h3>{veriler.name} {veriler.surname}</h3>
                                <img className='upUserPP' src={veriler.pp} />
                                <a onClick={()=>copyLink()} className='copyIcon'><img src={require('../assets/icons/copy.webp')} /></a>
                                {getProfile[0].id == userid ?(
                                    <a href={'/profile/'} className='settingIcon'><img src={require('../assets/icons/settings.webp')} /></a>
                                ):(
                                    <a href={'/message/'+id} className='messageIcon'><img src={require('../assets/icons/message.webp')} /></a>
                                )}
                            </div>
                            <div className='upUserMid'>
                                <h2>{veriler.name} {veriler.surname}</h2>
                                {veriler.approve ? (
                                    <img className='upUserMidApprove' src={require('../assets/icons/approved.webp')} />
                                ) : ''}
                                <a>@{veriler.username}</a>
                            </div>
                            <div className='upUserFllw'>
                                <a><b>{veriler.followers}</b> {langData.follower}</a>
                                <a><b>{veriler.subscribe}</b> {langData.subscribe}</a>
                                <a><b>{veriler.timages}</b> {langData.images}</a>
                                <a><b>{veriler.tvideos}</b> {langData.videos}</a>
                            </div>
                            <div className='upUserDescription'>
                                {(parser.parseFromString(he.decode(veriler.description), 'text/html')).documentElement.textContent}
                            </div>
                            {veriler.selfProfil ? ('') : (
                                <div>
                                    <div className='upUserF'>
                                        {veriler.follow ? (
                                            <a id="followbtn" onClick={() => followUser(userid, veriler.id, dil)}>{langData.unfollow}</a>
                                        ) : (
                                            <a id="followbtn" onClick={() => followUser(userid, veriler.id, dil)}>{langData.follow}</a>
                                        )
                                        }
                                    </div>
                                    {veriler.subscribe > 0 ? (
                                        <div id="mmbfs1" onClick={() => subscribeUser(userid, veriler.id, dil, 1, veriler.memberfee, veriler.memberfee2, veriler.memberfee3, veriler.memberfee6, veriler.memberfee12)} className='upUserS'>
                                            <a id="subbtn1">{langData.subscribed} </a>
                                            <a id='subsbtn1' className="upUserSPrice">{langData.subscribed_text}</a>
                                        </div>
                                    ) : (
                                        <div>
                                            {veriler.memberfee > 0 && (
                                                <div id="mmbfs1" onClick={() => subscribeUser(userid, veriler.id, dil, 1, veriler.memberfee, veriler.memberfee2, veriler.memberfee3, veriler.memberfee6, veriler.memberfee12)} className='upUserS'>
                                                    <a id="subbtn1">{langData.subscribe} </a>
                                                    <a id='subsbtn1' className="upUserSPrice">
                                                        ${formatter.format(veriler.memberfee)} {langData.per} {langData.month}
                                                    </a>
                                                </div>
                                            )}
                                            {veriler.memberfee2 > 0 && (
                                                <div id="mmbfs2" onClick={() => subscribeUser(userid, veriler.id, dil, 2, veriler.memberfee, veriler.memberfee2, veriler.memberfee3, veriler.memberfee6, veriler.memberfee12)} className='upUserS'>
                                                    <a id="subbtn2">{langData.subscribe} </a>
                                                    <a id="subsbtn2" className="upUserSPrice">${formatter.format(veriler.memberfee2)} {langData.per} 2 {langData.month}</a>
                                                </div>
                                            )}
                                            {veriler.memberfee3 > 0 && (
                                                <div id="mmbfs3"  onClick={() => subscribeUser(userid, veriler.id, dil, 3, veriler.memberfee, veriler.memberfee2, veriler.memberfee3, veriler.memberfee6, veriler.memberfee12)}className='upUserS'>
                                                    <a id="subbtn3">{langData.subscribe} </a>
                                                    <a id="subsbtn3" className="upUserSPrice">${formatter.format(veriler.memberfee3)} {langData.per} 3 {langData.month}</a>
                                                </div>
                                            )}
                                            {veriler.memberfee6 > 0 && (
                                                <div id="mmbfs6"  onClick={() => subscribeUser(userid, veriler.id, dil, 6, veriler.memberfee, veriler.memberfee2, veriler.memberfee3, veriler.memberfee6, veriler.memberfee12)}className='upUserS'>
                                                    <a id="subbtn6">{langData.subscribe} </a>
                                                    <a id="subsbtn6" className="upUserSPrice">${formatter.format(veriler.memberfee6)} {langData.per} 6 {langData.month}</a>
                                                </div>
                                            )}
                                            {veriler.memberfee12 > 0 && (
                                                <div id="mmbfs12"  onClick={() => subscribeUser(userid, veriler.id, dil, 12, veriler.memberfee, veriler.memberfee2, veriler.memberfee3, veriler.memberfee6, veriler.memberfee12)}className='upUserS'>
                                                    <a id="subbtn12">{langData.subscribe} </a>
                                                    <a id="subsbtn12" className="upUserSPrice">${formatter.format(veriler.memberfee12)} {langData.per} 12 {langData.month}</a>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className='upUserContent'>
                                <div className='upUcTop'>
                                    <a onClick={() => {
                                        document.getElementById('upUcPosts').style.display = "block"; document.getElementById('upUcMedias').style.display = "none";
                                        document.getElementById('postsbtn').classList.remove('upUcTopNormal'); document.getElementById('postsbtn').classList.add('upUcTopActive');
                                        document.getElementById('postsmedia').classList.remove('upUcTopActive'); document.getElementById('postsmedia').classList.add('upUcTopNormal');
                                    }}
                                        className='upUcTopActive' id='postsbtn'>{veriler.timages} {langData.posts}</a>
                                    <a onClick={() => {
                                        document.getElementById('upUcMedias').style.display = "block"; document.getElementById('upUcPosts').style.display = "none";
                                        document.getElementById('postsmedia').classList.remove('upUcTopNormal'); document.getElementById('postsmedia').classList.add('upUcTopActive');
                                        document.getElementById('postsbtn').classList.remove('upUcTopActive'); document.getElementById('postsbtn').classList.add('upUcTopNormal');
                                    }}
                                        className='upUcTopNormal' id='postsmedia'>{veriler.tvideos} {langData.media}</a>
                                </div>
                                <div id='upUcPosts'>
                                    {getPosts ? (
                                        <div className='midAreaContents'>
                                            {getPosts.map(product =>
                                                <div key={product.id} id={'posts' + product.id} className='midAreaContent'>
                                                    <div className='macUserInfo'>
                                                        <img src={product.pp} />
                                                        <p>{product.name} {product.surname}</p>
                                                        <a href={'user-detail/'+product.userid}>@{product.username}</a>
                                                        <div className='postFunctions'>
                                                            <img onClick={()=>opPfm('pfm'+product.id)} src={require('../assets/icons/threedots.webp')}/>
                                                            <div className='postFunctionsMenu' id={'pfm'+product.id}>
                                                                <span onClick={()=>window.location.href='/post/'+product.id}>Post Detail</span>
                                                                <span onClick={()=>opReport(product.userid, 'post', product.id, product.title)}>Report Post</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='macContentDesc'>
                                                        <h6>{(parser.parseFromString(he.decode(product.title), 'text/html')).documentElement.textContent}</h6>
                                                        {(parser.parseFromString(he.decode(product.description), 'text/html')).documentElement.textContent}
                                                    </div>

                                                    {product.image ? (
                                                        <div className='macContentImage'>
                                                            {product.cansee}
                                                            <div>
                                                                {(() => {
                                                                    if (product.cansee === 'see') {
                                                                        return <img onClick={()=>window.location.href='/post/'+product.id} style={{cursor:'pointer'}} src={product.image} />;
                                                                    } else if (product.cansee === 'hide') {
                                                                        return <div>
                                                                            <img src={stockimg} />;
                                                                            <div className='unLockPost'>
                                                                                {product.issubs > 0 ? (
                                                                                    <a>{langData.subscriber_post} {product.username}</a>
                                                                                ) : (
                                                                                    <a onClick={() => { buyPost(userid, product.id, dil) }}>{langData.unlock_text} {product.price} $</a>
                                                                                )}

                                                                            </div>
                                                                        </div>;
                                                                    } else {
                                                                        return <img src={stockimg} />;
                                                                    }
                                                                })()}
                                                            </div>
                                                        </div>
                                                    ) : ('')}
                                                    {product.video ? (
                                                        <div className='macContentVideo'>
                                                            {(() => {
                                                                if (product.cansee === 'see') {
                                                                    return <video width="100%;" height="400px" controls><source src={product.video} type="video/mp4" /></video>;
                                                                } else if (product.cansee === 'hide') {

                                                                    return <div>
                                                                        <img src={stockimg} />;
                                                                        <div className='unLockPost'>
                                                                            {product.issubs > 0 ? (
                                                                                <a>{langData.subscriber_post} {product.username}</a>
                                                                            ) : (
                                                                                <a onClick={() => { buyPost(userid, product.id, dil) }}>{langData.unlock_text} {product.price} $</a>
                                                                            )}

                                                                        </div>
                                                                    </div>;
                                                                } else {

                                                                    return <img src={stockimg} />;
                                                                }
                                                            })()}
                                                        </div>
                                                    ) : ('')}
                                                    {product.ares2 ? (
                                                        <div className='macContentImages'>
                                                            {(() => {
                                                                if (product.cansee === 'see') {
                                                                    return <img onClick={()=>window.location.href='/post/'+product.id} style={{cursor:'pointer'}} src={product.ares2} />;
                                                                } else if (product.cansee === 'hide') {
                                                                    return <img src={stockimg} />;
                                                                } else {
                                                                    return <img src={stockimg} />;
                                                                }
                                                            })()}

                                                            {(() => {
                                                                if (product.cansee === 'see' && product.ares3 != '') {
                                                                    return <img onClick={()=>window.location.href='/post/'+product.id} style={{cursor:'pointer'}} src={product.ares3} />;
                                                                } else if (product.cansee === 'hide') {
                                                                    return <img src={stockimg} />;
                                                                } else {
                                                                    return <img src={stockimg} />;
                                                                }
                                                            })()}
                                                            {(() => {
                                                                if (product.cansee === 'see' && product.ares4 != '') {
                                                                    return <img onClick={()=>window.location.href='/post/'+product.id} style={{cursor:'pointer'}} src={product.ares4} />;
                                                                } else if (product.cansee === 'hide') {
                                                                    return <img src={stockimg} />;
                                                                } else {
                                                                    return <img src={stockimg} />;
                                                                }
                                                            })()}
                                                        </div>
                                                    ) : ('')}
                                                    <div className='macContentFunctions'>
                                                        {product.ulike > 0 ? (
                                                            <a onClick={() => postLike(userid, product.id, dil)} className='macContentFunction'><img src={require('../assets/icons/heartdoll.webp')} /><p>{product.like}</p></a>
                                                        ) : (
                                                            <a onClick={() => postLike(userid, product.id, dil)} className='macContentFunction'><img src={require('../assets/icons/heart.webp')} /><p>{product.like}</p></a>
                                                        )}
                                                        <a onClick={() => opComment(product.id)} className='macContentFunction'><img src={require('../assets/icons/comment.webp')} /></a>
                                                        <a onClick={() => { getTip(product.userid, product.id, product.pp, product.username, product.name, product.surname) }} className='macContentFunction'><img src={require('../assets/icons/tip.webp')} /></a>
                                                        {product.ubook > 0 ? (
                                                            <a onClick={() => postBook(userid, product.id, dil)} className='macContentBookmark'><img src={require('../assets/icons/bookmarkdoll.webp')} /></a>
                                                        ) : (
                                                            <a onClick={() => postBook(userid, product.id, dil)} className='macContentBookmark'><img src={require('../assets/icons/bookmark.webp')} /></a>
                                                        )}
                                                    </div>
                                                    <div id={'comment' + product.id} className='macContentComments'>
                                                        {product.comments ? (
                                                            <div>
                                                                {product.comments.map(comment =>
                                                                    <div key={comment.id} className='macContentComment'>
                                                                        <img src={comment.pp} />
                                                                        <p>{comment.name} {comment.surname}</p>
                                                                        <a href={'/user-dteail/' + comment.id}>@{comment.username}</a>
                                                                        <div className='macContentCommentData'>
                                                                            {comment.comment}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ) : ('')}
                                                        <div className='macContentAddComment'>
                                                            <h5>{langData.add_comment}</h5>
                                                            <div id={"emoji" + product.id} className='macContentEmojiArea'>
                                                                {emojis.map((emoji) => (
                                                                    <a key={emoji} onClick={() => addEmoji(emoji)} style={{ margin: '5px' }}>{emoji}</a>
                                                                ))}
                                                            </div>

                                                            <div className='macContentTextArea'>
                                                                <textarea id={'commentText' + product.id} value={text} onChange={textChange} rows="4" cols="50"></textarea>
                                                                <a onClick={() => { document.getElementById('emoji' + product.id).style.display === "block" ? (document.getElementById('emoji' + product.id).style.display = "none") : (document.getElementById('emoji' + product.id).style.display = "block") }
                                                                } className='emoji'><img src={require("../assets/icons/emoji.webp")} /></a>
                                                                <a onClick={() => sendComment(userid, product.id, dil)} className='send'><img src={require("../assets/icons/send.webp")} /></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ) : ('')}
                                </div>
                                <div id='upUcMedias'>
                                    {getPosts ? (
                                        <div className='mediaAreaMedias'>
                                            <div className='mamTab'>
                                                <a id='mamtAll' className='mamTabActive'  onClick={() => { chMamTab('all') }}>{langData.all}</a>
                                                <a id='mamtImages' onClick={() => { chMamTab('images') }}>{langData.images}</a>
                                                <a id='mamtVideos' onClick={() => { chMamTab('videos') }}>{langData.videos}</a>
                                            </div>
                                            {getPosts.map(product =>
                                            <div key={product.id}>
                                                {product.video != '' ? (
                                                    <div className='mediaAreaMedia mamVideo'>
                                                        <a key={'9182' + product.id} onClick={() => { openMedia(product.id, userid, 'now', dil, 'mainimg', product.userid, lastGid) }} className='mediaAreaMedia1'>
                                                        {product.cansee == 'see' ? (
                                                            <video ><source src={product.video} type="video/mp4" /></video>
                                                        ):(
                                                            <img src={stockimg} />
                                                        )}
                                                        </a>
                                                    </div>
                                                    ):(
                                                    <div>
                                                        <div className='mediaAreaMedia mamImage'>
                                                            <a key={'9182' + product.id} onClick={() => { openMedia(product.id, userid, 'now', dil, 'mainimg', product.userid, lastGid) }} className='mediaAreaMedia1'>
                                                            {product.cansee == 'see' ? (
                                                                <div className='mediaAreaMedia1'>
                                                                    <img src={product.image} />
                                                                </div>
                                                            ):(
                                                                <img src={stockimg} />
                                                            )}
                                                            </a>
                                                        </div>
                                                        {/*product.images ? (
                                                        <div>
                                                            {product.images.map(simages => 
                                                            <div className='mediaAreaMedia mamImage'>
                                                                <a key={'9182' + simages.id} onClick={() => { openMedia(simages.id, userid, 'now', dil, 'subimg') }} className='mediaAreaMedia1'>
                                                                {product.cansee == 'see' ? (
                                                                    <div className='mediaAreaMedia1'>
                                                                        <img src={simages.image} />
                                                                    </div>
                                                                ):(
                                                                    <img src={stockimg} />
                                                                )}
                                                                </a>
                                                            </div>
                                                            )}
                                                        </div>
                                                        ):('')*/}
                                                    </div>
                                                )}
                                            </div>
                                            )}
                                        </div>
                                    ) : ('')}
                                </div>
                            </div>
                        </div>
                    ) : ('')}
                </div>
                <div className='rightAreaC'>
                    <Right></Right>
                </div>
            </div>
        </div >
    );
};

export default UserDetail;