import React, { Component, useEffect, useState } from 'react'
import Slider from 'react-slick';
import Story from './subComponents/Story';
import da from "../lang/da.json";
import de from "../lang/de.json";
import en from "../lang/en.json";
import es from "../lang/es.json";
import fr from "../lang/fr.json";
import it from "../lang/it.json";

let searchRow = 0;
let tRow = 0;
function Right(){
    let userid = localStorage.getItem('userid');
    let localDocs = localStorage.getItem('localDocs');

    const [userData, setUserData] = useState([]);
    const [langData, setLangData] = useState(en);
    const [policyData, setPolicyData] = useState([]);
    const fetchUserData = () => {
        fetch(localDocs+'/rn-adaptor/l-user?id=' + userid)
            .then(responseUD => responseUD.json())
            .then(userData => {
                setUserData(userData);
                if(userData.lang){
                  if(userData.lang == "en"){setLangData(en)}
                  if(userData.lang == "da"){setLangData(da)}
                  if(userData.lang == "de"){setLangData(de)}
                  if(userData.lang == "es"){setLangData(es)}
                  if(userData.lang == "fr"){setLangData(fr)}
                  if(userData.lang == "it"){setLangData(it)}
                }else{
                  setLangData(en);
                }
              })
            .catch(errorUD => console.log('Fetch error : ', errorUD));
    }

    const [suggestData, setSuggestData] = useState([]);
    const [slideData, setSlideData] = useState([]);
    const fetchSuggestData = () => {
        fetch(localDocs+'/rn-adaptor/l-searchData?id='+userid+'&searchKey='+searchKey+'&type='+searchRow)
            .then(responseSGG => responseSGG.json())
            .then(suggestData => {setSuggestData(suggestData);document.getElementById('suggestCover').style.display="none";tRow = suggestData[0].totalData;})
            .catch(errorSGG => console.error('Fetch error : ', errorSGG));
        }
    const fetchSlideData = () => {
        fetch(localDocs+'/rn-adaptor/l-freePosts?userid='+userid)
            .then(responseSD => responseSD.json())
            .then(slideData => setSlideData(slideData))
            .catch(errorSD => console.error('Fetch error : ', errorSD));
        }
    const fetchPolicyData = () => {
        fetch(localDocs+'/rn-adaptor/l-policies?userid='+userid)
            .then(responsePD => responsePD.json())
            .then(policyData => setPolicyData(policyData))
            .catch(errorPD => console.error('Fetch error : ', errorPD));
        }
        //console.log(policyData);
    useEffect(() => {
        fetchSuggestData();
        fetchSlideData();
        fetchUserData();
        fetchPolicyData();
    }, []);
    let searchKey = "";
    function runSearch(){
        searchKey = document.getElementById('raSearchInput').value;
        searchRow = 0;
        fetchSuggestData();
    }
    function NextArrow(props) { const { className, style, onClick } = props; return ( <div className={className} style={{ ...style, display: "block", background: "transparent", top: "-35px", right : "20px", fontSize : '20px' }} onClick={onClick} /> );}
    function PrevArrow(props) { const { className, style, onClick } = props; return ( <div className={className} style={{ ...style, display: "block", background: "transparent", top: "-35px", left: "auto", right : "55px" }} onClick={onClick} /> );}
    const settings = {
        dots: false, infinite: false, speed: 500, slidesToShow: 5, slidesToScroll: 5, vertical: true, verticalSwiping: false, nextArrow: <NextArrow />, prevArrow: <PrevArrow />,
        responsive: [
            {breakpoint: 1024, settings: {slidesToShow: 3, slidesToScroll: 3, infinite: true, dots: true } },
            {breakpoint: 600, settings: {slidesToShow: 2, slidesToScroll: 2, initialSlide: 2 } },
            {breakpoint: 480, settings: {slidesToShow: 1, slidesToScroll: 1 }}
        ]
    };
    function suggestFunc(type){
        if(type == 'next'){
            if(searchRow + 5 > tRow){
                
            }else{
                searchRow = searchRow + 5;
            }
        }else{
            if(searchRow>4){
                searchRow = searchRow - 5;
            }
        }
        //console.log(searchRow);
        document.getElementById('suggestCover').style.display="flex";
        fetchSuggestData();
    }
    return (
        
        <div className='rightArea'>
            <div className='raSearch'>
                <input onKeyUp={()=>runSearch()} id='raSearchInput' placeholder={langData.search}/>
            </div>
            <div className='raSuggestions'>
                <div className='rasTitle'>
                    <h4>{langData.suggestions}</h4>
                    <a onClick={()=>suggestFunc('next')}><img src={require('../assets/icons/arrow.webp')} /> </a>
                    <a onClick={()=>suggestFunc('prev')}><img src={require('../assets/icons/arrow.webp')} /> </a>
                </div>
                <div className='rasUsers'>
                    <div className='suggestCover' id='suggestCover'><div className="spinner"></div></div>
                    {suggestData ? (
                        <div>
                            {suggestData.map(suggests => (
                                <div key={suggests.id}>
                                    <a key={suggests.id} href={'/user-detail/'+suggests.id} className='rasUser' style={{ float: 'left', width: '100%', background: `url(${suggests.background})`, }}>
                                        <div className='rasUserCover'></div>
                                        <div className='rasUserData'>
                                            <img src={suggests.pp} />
                                            <p>{suggests.name} {suggests.surname}</p>
                                            <span>@{suggests.username}</span>
                                        </div>
                                    </a>
                                </div>
                            ))}
                            </div>
                    ):''}
                </div>
            </div>
            
            <div className='rasStories'>
                <Story datas = {slideData} />
            </div>
            
            <div className='rasPolicies'>
            {policyData ? (
                <ul>
                    {policyData.map ( allPolicies => 
                    <li key={allPolicies.id}><a href={'/pages/'+allPolicies.id} target="_blank">{allPolicies.title}</a></li>
                    )}
                </ul>
            ):''}
            </div>
        </div>
    )
}
export default Right;