import React, { useEffect, useState } from 'react'
import he from 'he';
import { useParams } from 'react-router-dom';
import alertify from 'alertifyjs';
import Left from './Left';
/*const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});*/
function PostDetail({langData}) {
    let { id } = useParams();
    let userid = localStorage.getItem('userid');
    let dil = localStorage.getItem('dil');
    let stockimg = localStorage.getItem('stockimg');
    let localDocs = localStorage.getItem('localDocs');


    const [getProfile, setGetProfile] = useState([]);
    const [getPosts, setGetPosts] = useState([]);
    const fetchGetProfile = () => {
        fetch(localDocs+'/rn-adaptor/l-userProfile?id=' + id + "&userid=" + userid)
            .then(responseGP => responseGP.json())
            .then(getProfile => setGetProfile(getProfile))
            .catch(errorGP => console.error('Fetch error : ', errorGP));
    }
    const fetchGetPosts = () => {
        fetch(localDocs+'/rn-adaptor/l-postDetail?id=' + id + '&userid=' + userid)
            .then(responseGPo => responseGPo.json())
            .then(getPosts => setGetPosts(getPosts))
            .catch(errorGPo => console.error('Fetch error : ', errorGPo));
    }
    useEffect(() => {
        fetchGetProfile();
        fetchGetPosts();
    }, []);
    const parser = new DOMParser();
    if (!getPosts) { window.location.href = '/explore'; }
    console.log(getPosts);
    const [getNotify, setGetNotify] = useState([]);
    const fetchGetNotify = () => {
        fetch(localDocs+'/rn-adaptor/l-notifications?&userid=' + userid)
            .then(responseGPo => responseGPo.json())
            .then(getNotify => setGetNotify(getNotify))
            .catch(errorNtf => console.error('Fetch error : ', errorNtf));
    }
    useEffect(() => {
        fetchGetNotify();
    }, []);
    const postLike = async (userid, postid, dil) => {
        const data = { userid: userid, postid: postid, dil: dil };
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-postLike.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const plJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", plJson);
            fetchGetPosts();
        } catch (error) { console.log("An error :", error); }
    };
    const postBook = async (userid, postid, dil) => {
        const data = { userid: userid, postid: postid, dil: dil };
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-addBookmarkt.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const pbJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", pbJson);
            fetchGetPosts();
        } catch (error) { console.log("An error :", error); }
    };
    const [text, setText] = useState([]);
    const emojis = ["😀", "😁", "😅", "😄", "😂", "🤣", "🥰", "😍", "💑", "😎", "🤔", "😠", "😞", "😪", "😭", "🎈"];

    const addEmoji = (emoji) => {
        setText(text + emoji);
    }
    const textChange = (event) => {
        setText(event.target.value);
    }
    const sendComment = async (userid, postid, dil) => {
        const tArea = document.getElementById('commentText' + postid).value;
        const data = { userid: userid, postid: postid, dil: dil, comment: tArea };
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-sendComment.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const plJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", plJson);
            fetchGetPosts();
            alertify.success(plJson.message, 2);
            document.getElementById('commentText' + postid).value = "";
            setText('');
        } catch (error) { console.log("An error :", error); }
    };
    const sendTip = async (userid, dil) => {
        const tipArea = document.getElementById('tipAmount').value;
        const tipComment = document.getElementById('tipComment').value;
        const tipUser = document.getElementById('mtAreaUser').innerHTML;
        const tipPost = document.getElementById('mtAreaPost').innerHTML;
        const data = { userid: userid, dil: dil, tip: tipArea, comment: tipComment, ouser: tipUser, postid: tipPost };
        //console.log(data);
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-sendTip.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const plJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", plJson);
            fetchGetPosts();
            document.getElementById('modalTip').style.display = "none";
            document.getElementById('tipAmount').value = "";
            document.getElementById('tipComment').value = "";
            if (plJson.status == 0) { alertify.error(plJson.message, 2); }
            if (plJson.status == 1) { alertify.success(plJson.message, 2); }

        } catch (error) { console.log("An error :", error); }
    };
    //document.getElementById('comment' + id).style.display="block";
    function opComment(id) {
        var cArea = document.getElementById('comment' + id);
        if (cArea.style.display == "block") {
            cArea.style.display = "none";
        } else {
            cArea.style.display = "block";
        }
    }


    const getTip = async (userid, postid) => {
        const data = { userid: userid };
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-getTip.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const gtJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", gtJson);
            document.getElementById('modalTip').style.display = "flex";
            document.getElementById('mtAreaImg').src = gtJson.pp;
            document.getElementById('mtAreaP').innerHTML = gtJson.name + ' ' + gtJson.surname;
            document.getElementById('mtAreaA').href = '/user-detail' + gtJson.userid;
            document.getElementById('mtAreaA').innerHTML = '@' + gtJson.username;
            document.getElementById('mtAreaUser').innerHTML = userid;
            document.getElementById('mtAreaPost').innerHTML = postid;
        } catch (error) { console.log("An error :", error); }
    };
    const buyPost = async (userid, postid, dil) => {
        const data = { userid: userid, postid: postid, dil: dil }
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-buyPost.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error('HTTP error! status : ${response.status}'); }
            const bpJson = await response.json();
            if (bpJson.status == 0) { alertify.error(bpJson.message, 2); }
            if (bpJson.status == 1) { alertify.success(bpJson.message, 2); }
            fetchGetPosts();
            console.log(bpJson);
        } catch (error) { console.log("An error : ", error); }
    }
    function mClose() {
        document.getElementById('modalMedia').style.display = "none";
        document.querySelector("#modalVideo").pause();
    }
    const openMedia = async (road, type) => {
        document.getElementById('modalMedia').style.display = "flex";
        if (type == "video") {
            document.getElementById('mmDatasImage').style.display = "none";
            document.getElementById('mmDatasVideo').style.display = "flex";
            document.getElementById('modalVideo').src = road;
        } else {
            document.getElementById('mmDatasImage').style.display = "flex";
            document.getElementById('mmDatasVideo').style.display = "none";
            document.getElementById('modalImage').src = road;
        }
    };
    /*const nextMedia = () => { openMedia(latestID, userid, 'next', dil, latestMtip, latestOtherId); }
    const prevMedia = () => { openMedia(latestID, userid, 'prev', dil, latestMtip, latestOtherId); }*/
    return (
        <div>
            <div id="modalTip" className="modalTip">
                <a onClick={() => document.getElementById('modalTip').style.display = "none"} className='mmClose'></a>
                <div className='mtArea'>
                    <h5>{langData.send_tip}</h5>
                    <span onClick={() => document.getElementById('modalTip').style.display = "none"}>x</span>
                    <div className='mtUserArea'>
                        <img id="mtAreaImg" src={stockimg} />
                        <p id="mtAreaP">Name Surname</p>
                        <a href={'/user-detail/1'} id='mtAreaA'>@username</a>
                    </div>
                    <p id='mtAreaUser'>0</p>
                    <p id='mtAreaPost'>0</p>
                    <div className='mtAmountArea'>
                        <p>{langData.amount}</p>
                        <img src={require('../assets/icons/tip.webp')} />
                        <input id="tipAmount" placeholder={langData.amount} />
                    </div>
                    <div className='mtDescArea'>
                        <p>{langData.message}</p>
                        <textarea id='tipComment' placeholder={langData.message}></textarea>
                    </div>
                    <div className='mtButtonArea'>
                        <a onClick={() => document.getElementById('modalTip').style.display = "none"}>{langData.cancel}</a>
                        <a onClick={() => sendTip(userid, dil)}>{langData.send_tip}</a>
                    </div>
                </div>
            </div>
            <div id='modalMedia' className='modalMedia'>
                <a onClick={() => mClose()} className='mmClose'></a>
                <div className='mmDatas'>
                    {/*<a onClick={() => prevMedia()} className='mmDatasLeft' id='mmDatasLeft'><img src={require('../assets/icons/arrow.webp')} /></a>*/}
                    <div className='mmDatasImage' id='mmDatasImage'>
                        <img id='modalImage' src={stockimg} />
                    </div>
                    <div className='mmDatasVideo' id='mmDatasVideo'>
                        <video id='modalVideo' controls  controlsList="nodownload"><source id='modalVideoSrc' src={stockimg} type="video/mp4" /></video>
                    </div>
                    {/*<a onClick={() => nextMedia()} className='mmDatasRight' id='mmDatasRight'><img src={require('../assets/icons/arrow.webp')} /></a>*/}
                </div>
            </div>
            <div className='leftAreaC'>
                <Left title="Title" />
            </div>
            <div className='fullAreaC'>
                <div className='midArea'>
                    <div className='postArea'>
                        {getPosts ? (
                            <div className='midAreaContents'>
                                {getPosts.map(product =>
                                    <div key={product.id} id={'posts' + product.id} className='midAreaContent'>
                                        <div className='macUserInfo'>
                                            <img src={product.pp} />
                                            <p>{product.name} {product.surname}</p>
                                            <a href={'/user-detail/' + product.userid}>@{product.username}</a>
                                        </div>
                                        <div className='macContentDesc'>
                                            <h6>{(parser.parseFromString(he.decode(product.title), 'text/html')).documentElement.textContent}</h6>
                                            {(parser.parseFromString(he.decode(product.description), 'text/html')).documentElement.textContent}
                                        </div>

                                        {product.image ? (
                                            <div className='macContentImage'>
                                                {product.cansee}
                                                <div>
                                                    {product.cansee == 'see' ? (
                                                        <img onClick={() => { openMedia(product.image, 'image') }} className='postDetailImg' src={product.image} />
                                                    ) : (
                                                        <div>
                                                            <img src={stockimg} />;
                                                            <div className='unLockPost'>
                                                                {product.issubs > 0 ? (
                                                                    <a>{langData.subscriber_post} {product.username}</a>
                                                                ) : (
                                                                    <a onClick={() => { buyPost(userid, product.id, dil) }}>{langData.unlock_text} {product.price} $</a>
                                                                )}

                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ) : ('')}
                                        {product.images && product.cansee == 'see' ? (
                                            <div className='macContentSubImages'>
                                                {product.images.map(subImage =>
                                                    <div className='macContentSubImage' key={subImage.id}>
                                                        <img onClick={() => { openMedia(subImage.image, 'image') }} src={subImage.image} />
                                                    </div>
                                                )}
                                            </div>
                                        ) : ('')}
                                        {product.video ? (
                                            <div className='macContentVideo'>
                                                {(() => {
                                                    if (product.cansee === 'see') {
                                                        return <video width="100%;" height="400px" controls  controlsList="nodownload"><source src={product.video} type="video/mp4" /></video>;
                                                    } else if (product.cansee === 'hide') {

                                                        return <div>
                                                            <img src={stockimg} />;
                                                            <div className='unLockPost'>
                                                                {product.issubs > 0 ? (
                                                                    <a>{langData.subscriber_post} {product.username}</a>
                                                                ) : (
                                                                    <a onClick={() => { buyPost(userid, product.id, dil) }}>{langData.unlock_text} {product.price} $</a>
                                                                )}

                                                            </div>
                                                        </div>;
                                                    } else {

                                                        return <img src={stockimg} />;
                                                    }
                                                })()}
                                            </div>
                                        ) : ('')}
                                        {product.ares2 ? (
                                            <div className='macContentImages'>
                                                {(() => {
                                                    if (product.cansee === 'see') {
                                                        return <img src={product.ares2} />;
                                                    } else if (product.cansee === 'hide') {
                                                        return <img src={stockimg} />;
                                                    } else {
                                                        return <img src={stockimg} />;
                                                    }
                                                })()}

                                                {(() => {
                                                    if (product.cansee === 'see' && product.ares3 != '') {
                                                        return <img src={product.ares3} />;
                                                    } else if (product.cansee === 'hide') {
                                                        return <img src={stockimg} />;
                                                    } else {
                                                        return <img src={stockimg} />;
                                                    }
                                                })()}
                                                {(() => {
                                                    if (product.cansee === 'see' && product.ares4 != '') {
                                                        return <img src={product.ares4} />;
                                                    } else if (product.cansee === 'hide') {
                                                        return <img src={stockimg} />;
                                                    } else {
                                                        return <img src={stockimg} />;
                                                    }
                                                })()}
                                            </div>
                                        ) : ('')}
                                        <div className='macContentFunctions'>
                                            {product.ulike > 0 ? (
                                                <a onClick={() => postLike(userid, product.id, dil)} className='macContentFunction'><img src={require('../assets/icons/heartdoll.webp')} /><p>{product.like}</p></a>
                                            ) : (
                                                <a onClick={() => postLike(userid, product.id, dil)} className='macContentFunction'><img src={require('../assets/icons/heart.webp')} /><p>{product.like}</p></a>
                                            )}
                                            <a onClick={() => opComment(product.id)} className='macContentFunction'><img src={require('../assets/icons/comment.webp')} /></a>
                                            <a onClick={() => { getTip(product.userid, product.id, product.pp, product.username, product.name, product.surname) }} className='macContentFunction'><img src={require('../assets/icons/tip.webp')} /></a>
                                            {product.ubook > 0 ? (
                                                <a onClick={() => postBook(userid, product.id, dil)} className='macContentBookmark'><img src={require('../assets/icons/bookmarkdoll.webp')} /></a>
                                            ) : (
                                                <a onClick={() => postBook(userid, product.id, dil)} className='macContentBookmark'><img src={require('../assets/icons/bookmark.webp')} /></a>
                                            )}
                                        </div>
                                        <div id={'comment' + id} className='macContentComments'>
                                            {product.comments ? (
                                                <div>
                                                    {product.comments.map(comment =>
                                                        <div key={comment.id} className='macContentComment'>
                                                            <img src={comment.pp} />
                                                            <p>{comment.name} {comment.surname}</p>
                                                            <a href={'/user-dteail/' + comment.id}>@{comment.username}</a>
                                                            <div className='macContentCommentData'>
                                                                {comment.comment}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : ('')}
                                            <div className='macContentAddComment'>
                                                <h5>{langData.add_comment}</h5>
                                                <div id={"emoji" + product.id} className='macContentEmojiArea'>
                                                    {emojis.map((emoji) => (
                                                        <a key={emoji} onClick={() => addEmoji(emoji)} style={{ margin: '5px' }}>{emoji}</a>
                                                    ))}
                                                </div>

                                                <div className='macContentTextArea'>
                                                    <textarea id={'commentText' + product.id} value={text} onChange={textChange} rows="4" cols="50"></textarea>
                                                    <a onClick={() => { document.getElementById('emoji' + product.id).style.display === "block" ? (document.getElementById('emoji' + product.id).style.display = "none") : (document.getElementById('emoji' + product.id).style.display = "block") }
                                                    } className='emoji'><img src={require("../assets/icons/emoji.webp")} /></a>
                                                    <a onClick={() => sendComment(userid, product.id, dil)} className='send'><img src={require("../assets/icons/send.webp")} /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : ('')}
                    </div>
                </div>
            </div>
        </div>

    )
}
export default PostDetail;