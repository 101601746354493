import React, { Component, useEffect, useState } from 'react';
/*import '../assets/css/store.css';*/
import he from 'he';
import StoreHead from './subComponents/StoreHead';
import StoreFooter from './subComponents/StoreFooter';
function Store({langData}) {
    const parser = new DOMParser();
    const pp = require('../assets/images/klogo.webp');
    let userid = localStorage.getItem('userid');
    let dil = localStorage.getItem('dil');
    let stockimg = localStorage.getItem('stockimg');
    let localDocs = localStorage.getItem('localDocs');
    const [getCats, setGetCats] = useState([]);
    const fetchGetCats = () => {
        fetch(localDocs+'/rn-adaptor/sfunctions?islem=getCat&area=storeMain')
            .then(responseCat => responseCat.json())
            .then(getCats => setGetCats(getCats))
            .catch(errorCat => console.error('Fetch error : ', errorCat));
    }
    const [getMain, setGetMain] = useState([]);
    const fetchGetMain = () => {
        fetch(localDocs+'/rn-adaptor/sfunctions?islem=mainProduct')
            .then(responseMain => responseMain.json())
            .then(getMain => setGetMain(getMain))
            .catch(errorMain => console.error('Fetch error : ', errorMain));
    }
    const [getStore, setGetStore] = useState([]);
    const fetchGetStore = () => {
        fetch(localDocs+'/rn-adaptor/sfunctions?islem=storeMain')
            .then(responseStore => responseStore.json())
            .then(getStore => setGetStore(getStore))
            .catch(errorStore => console.error('Fetch error : ', errorStore));
    }
    useEffect(() => {
        fetchGetCats();
        fetchGetMain();
        fetchGetStore();
    }, []);
    return (
        <div className='storePage'>
            <StoreHead datas = {StoreHead} langData = {langData}/>
            <div className='storeBanner'>
                <img onClick={()=>window.location.href = getStore.slide_link} src={getStore.slide} />
            </div>
            
            <div className='containers'>
                <div className='storeBody'>
                    <p>{langData.store_main_text}</p>
                    <div className='sbExploreArea'>
                        <h5>Explore It All</h5>
                        <a href='/categories'>{langData.view_all}</a>
                        {getCats.map ( categories => 
                        <div onClick={()=>window.location.href = '/categories/'+categories.seo} key={categories.id} className='sbeaItem'>
                            <img src={categories.image} />
                            <p>{(parser.parseFromString(he.decode(categories.title), 'text/html')).documentElement.textContent}</p>
                        </div>
                        )}
                    </div>
                   {getMain ?(
                    <div>
                    {getMain.map ( mkat =>
                    <div key={mkat.id} className='sbExploreArea'>
                        <h5>{mkat.title}</h5>
                        <a href={'/categories/'+mkat.id+'-'+mkat.seo}>{langData.view_all}</a>
                        {mkat.products.map ( products =>
                        <div key={products.id} onClick={()=>window.location.href = '/product/'+products.id} className='sbeaItem2'>
                            <img src={products.image} />
                            <p>{(parser.parseFromString(he.decode(products.title), 'text/html')).documentElement.textContent} </p>
                            {products.discount > 0 ?(
                            <div>
                                <span className='priceNew'>{products.discount}$</span>
                                <span className='priceOld'>{products.price}$</span>
                            </div>
                            ):(
                            <div>
                                <span className='priceNew'>{products.price}$</span>
                            </div>
                            )}
                        </div>
                        )}
                    </div>
                    )}
                    </div>
                    ):''}
                    <div onClick={()=>window.location.href=getStore.banner_link} className='sbBannerArea'>
                        <img src={getStore.banner} />
                    </div>
                </div>
            </div>
            <StoreFooter datas = {StoreFooter} langData = {langData} />
        </div>
    )
}
export default Store;
