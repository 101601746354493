import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Left from './Left';
import he from 'he';
const parser = new DOMParser();
function Notification({ langData }) {
    let { uniqid } = useParams();
    let userid = localStorage.getItem('userid');
    let dil = localStorage.getItem('dil');
    let localDocs = localStorage.getItem('localDocs');

    const [getData, setGetData] = useState([]);
    const [getTra, setGetTra] = useState([]);
    const [getWallet, setGetWallet] = useState([]);
    let fetchType = "all";
    const fetchGetMessage = () => {
        fetch(localDocs+'/rn-adaptor/l-transactions.php?&userid=' + userid + '&dil=' + dil + '&type=' + fetchType)
            .then(responseData => responseData.json())
            .then(getData => setGetData(getData))
            .catch(errorData => console.error('Fetch error : ', errorData));
    }
    useEffect(() => {
        fetchGetMessage();
    }, []);
    const fetchGetTra = () => {
        fetch(localDocs+'/rn-adaptor/functions.php?islem=getTra&userid=' + userid + '&dil=' + dil)
            .then(responseTra => responseTra.json())
            .then(getTra => setGetTra(getTra))
            .catch(errorTra => console.error('Fetch error : ', errorTra));
    }
    const fetchGetWallet = () => {
        fetch(localDocs+'/rn-adaptor/functions.php?islem=getWallet&userid=' + userid + '&dil=' + dil)
            .then(responseWallet => responseWallet.json())
            .then(getWallet => setGetWallet(getWallet))
            .catch(errorWallet => console.error('Fetch error : ', errorWallet));
    }
    useEffect(() => {
        fetchGetMessage();
        fetchGetTra();
        fetchGetWallet();
    }, []);
    console.log(getTra);
    //console.log(getData);

    let stockimg = localStorage.getItem('stockimg');
    const parser = new DOMParser();
    let lastMenu = 'wallet';
    function changeMenu(gMenu) {
        if (gMenu != lastMenu) {
            document.getElementById(gMenu).style.display = "block"; document.getElementById(lastMenu).style.display = "none";
            document.getElementById(lastMenu + 'A').classList.remove('atBodyActive'); document.getElementById(gMenu + 'A').classList.add('atBodyActive');
            lastMenu = gMenu;
        }
    }
    let lastSelect = 'selectAll';
    function changeSelect(gSelect) {
        document.getElementById("selectAll").style.display = "none";
        document.getElementById("selectSub").style.display = "none";
        document.getElementById("selectMsg").style.display = "none";
        document.getElementById("selectPos").style.display = "none";
        document.getElementById("selectTip").style.display = "none";
        document.getElementById("selectFun").style.display = "none";
        document.getElementById(lastSelect).style.display = "none";
        document.getElementById(gSelect).style.display = "block";
        document.getElementById(lastSelect + 'S').classList.remove('stSelectActive');
        document.getElementById('selectAllS').classList.remove('stSelectActive');
        document.getElementById('selectSubS').classList.remove('stSelectActive');
        document.getElementById('selectMsgS').classList.remove('stSelectActive');
        document.getElementById('selectPosS').classList.remove('stSelectActive');
        document.getElementById('selectTipS').classList.remove('stSelectActive');
        document.getElementById('selectFunS').classList.remove('stSelectActive');
        document.getElementById('selectStore').classList.remove('stSelectActive');
        document.getElementById(gSelect + 'S').classList.add('stSelectActive');
        lastSelect = gSelect;

        fetchType = gSelect;
        fetchGetMessage();
        //alert(gSelect + ' - '+lastSelect);
    }
    let lastTraType = "traSuccess";
    function changeTraType(gTraType) {
        document.getElementById('traSuccess').style.display = "none";
        document.getElementById('traError').style.display = "none";
        document.getElementById(lastTraType).style.display = "none"; document.getElementById(gTraType).style.display = "block";
        document.getElementById(lastTraType + 'S').classList.remove('stFilterActive'); document.getElementById(gTraType + 'S').classList.add('stFilterActive');
        lastTraType = gTraType;

    }
    let lastPrcType = "prcSuccess";
    function changeTraType2(gPrcType) {
        if (gPrcType != lastPrcType) {
            document.getElementById(lastPrcType).style.display = "none"; document.getElementById(gPrcType).style.display = "block";
            document.getElementById(lastPrcType + 'S').classList.remove('stFilterActive'); document.getElementById(gPrcType + 'S').classList.add('stFilterActive');
            lastPrcType = gPrcType;
        }
    }
    let lWallet = "myWallet";
    function chWallet(gWallet) {
        document.getElementById(lWallet).style.display = "none"; document.getElementById(gWallet).style.display = "block";
        document.getElementById(lWallet + 'S').classList.remove('stFilterActive'); document.getElementById(gWallet + 'S').classList.add('stFilterActive');
        lWallet = gWallet;
    }
    let lAM = "amCredit";
    function chAM(gAM) {
        document.getElementById(lAM).style.display = "none"; document.getElementById(gAM).style.display = "block";
        document.getElementById(lAM + 'S').classList.remove('stFilterActive'); document.getElementById(gAM + 'S').classList.add('stFilterActive');
        lAM = gAM;
    }

    const addMoney = (userid) => {
        let addDetails = document.getElementById('addDetails').value
        let addDate = document.getElementById('addDate').value
        let addFee = document.getElementById('addFee').value
        const formData = new FormData();
        formData.append('userid', userid);
        formData.append('addDetails', addDetails);
        formData.append('addDate', addDate);
        formData.append('addFee', addFee);
        for (let [key, value] of formData.entries()) {
            if (value instanceof File) {
                console.log(`${key}: ${value.name}, ${value.size}, ${value.type}`);
            } else {
                console.log(`${key}: ${value}`);
            }
        }
        fetch(localDocs+'/rn-adaptor/functions.php?islem=addFunds', { method: 'POST', body: formData })
        .then(response => { console.log(response.json());})
        .catch(error => { console.log(error); });
    };
    const bankWithraw = (userid) => {
        let witBank = document.getElementById('witBank').value
        let witIban = document.getElementById('witIban').value
        let witName = document.getElementById('witName').value
        let witFee = document.getElementById('witFee').value
        const formData = new FormData();
        formData.append('userid', userid);
        formData.append('witBank', witBank);
        formData.append('witIban', witIban);
        formData.append('witName', witName);
        formData.append('witFee', witFee);
        for (let [key, value] of formData.entries()) {
            if (value instanceof File) {
                console.log(`${key}: ${value.name}, ${value.size}, ${value.type}`);
            } else {
                console.log(`${key}: ${value}`);
            }
        }
        fetch(localDocs+'/rn-adaptor/functions.php?islem=bankWithraw', { method: 'POST', body: formData })
        .then(response => { console.log(response.json());window.location.reload();})
        .catch(error => { console.log(error); });
    };
    return (
        <div>
            <div className='leftAreaC'>
                <Left title="Title" />
            </div>
            <div className='fullAreaC'>
                <div className='allTra'>
                    <div className='atHeader'>
                        <a onClick={()=>window.history.back()} className='leftArrow'><img src={require('../assets/icons/leftarrow.webp')} /></a>
                        <h3>{langData.transactions}</h3>
                    </div>
                    <div className='atBody'>
                        <a onClick={() => { changeMenu('wallet'); }} className='atBodyActive' id='walletA'>{langData.my_wallet  }<img src={require('../assets/icons/arrow.webp')} /></a>
                        <a onClick={() => { changeMenu('traHis'); }} id='traHisA'>{langData.all_transactions}<img src={require('../assets/icons/arrow.webp')} /></a>
                        <a style={{display : "none"}} onClick={() => { changeMenu('traPurc'); }} id='traPurcA'>{langData.purchases} <img src={require('../assets/icons/arrow.webp')} /></a>
                        {/*<a>My Cards <img src={require('../assets/icons/arrow.webp')} /></a>*/}
                    </div>
                </div>
                <div id='wallet' className='selectTra'>
                    <div className='stHeader'>
                        {/*<a className='leftArrow'><img src={require('../assets/icons/leftarrow.webp')} /></a>*/}
                        <h3>{langData.my_wallet}</h3>
                    </div>
                    <div className='stFilter'>
                        <a onClick={() => chWallet('myWallet')} id='myWalletS' className='stFilterActive'>{langData.my_wallet}</a>
                        <a onClick={() => chWallet('addMoney')} id='addMoneyS'>{langData.add_money}</a>
                        <a onClick={() => chWallet('withrawMoney')} id='withrawMoneyS'>{langData.withraw_money}</a>
                    </div>
                    <div id='myWallet' className='myWallet'>
                        <div className='myWalletCredit'>
                            <p>{langData.balance} : <span>{getWallet.wallet}$</span></p>
                            {getTra ? (
                                <div>
                                    <p>{langData.pending_payments}</p>
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>ID</td>
                                                <td>{langData.description}</td>
                                                <td>{langData.total}</td>
                                                <td>{langData.type}</td>
                                                <td>{langData.date}</td>
                                                <td>{langData.status}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getTra.map(tras =>
                                            <tr key={tras.id}>
                                                <td>{tras.id}</td>
                                                <td>{tras.desc}</td>
                                                <td>{tras.total}$</td>
                                                {tras.type == "withraw"?(
                                                <td>{langData.withraw_money}</td>
                                                ):''}
                                                {tras.type == "banktransfer"?(
                                                <td>{langData.bank_transfer}</td>
                                                ):''}
                                                <td>{tras.date}</td>
                                                {tras.status == "Waiting"? (
                                                    <td>{langData.pending}</td>
                                                ):(
                                                    <td>{langData.denied}</td>
                                                )}
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            ) : ''}
                        </div>
                    </div>
                    <div id='addMoney' className='hidden'>
                        <div className='stFilter'>
                            <a onClick={() => chAM('amCredit')} className='stFilterActive' id='amCreditS'>{langData.credit_card}</a>
                            <a onClick={() => chAM('amBank')} id='amBankS'>{langData.bank_transfer}</a>
                        </div>
                        <div className='myWallet' id='amCredit'>
                            <p className='taC wmt-20'>You can use your credit card on CCBill</p>
                            <a href="https://api.ccbill.com/wap-frontflex/flexforms/c13cf01c-616d-48d2-a9d9-481e74b2c212" target='_blank' className='ccbillButton'>Pay with CCbill</a>
                        </div>
                        <div className='myWallet hidden' id='amBank'>
                            <p className='wmt-1'>
                                <b>Bank Name : </b> Bank Name <br/>
                                <b>IBAN Number : </b> EU12 0000 0000 0000 0000 12 <br/>
                                <b>Account Name : </b> Wk Media Gmbh <br/>
                            </p>
                            <div className='pdaDatas' style={{ display : 'block'}}>
                                <div className='pdaData'>
                                    <label>{langData.transfer_details}</label>
                                    <input id='addDetails' type='text'></input>
                                </div>
                                <div className='pdaData pdaDataHalf wmt-5'>
                                    <label>{langData.transfer_date}</label>
                                    <input id='addDate' type='datetime-local'></input>
                                </div>
                                <div className='pdaData pdaDataHalf wmt-5'>
                                    <label>{langData.total_fee}</label>
                                    <input id='addFee' type='number'></input>
                                </div>
                                <a onClick={()=>addMoney(userid)}>{langData.save}</a>
                            </div>
                        </div>
                    </div>
                    <div id='withrawMoney' className='hidden'>
                        <div className='pdaDatas' style={{ display : 'block'}}>
                            <div className='pdaData pdaDataHalf wmt-5'>
                                <label>{langData.bank}</label>
                                <input id='witBank' type='text'></input>
                            </div>
                            <div className='pdaData pdaDataHalf wmt-5'>
                                <label>{langData.iban_account}</label>
                                <input id='witIban' type='text'></input>
                            </div>
                            <div className='pdaData pdaDataHalf wmt-5'>
                                <label>{langData.fully_name}</label>
                                <input id='witName' type='text'></input>
                            </div>
                            <div className='pdaData pdaDataHalf wmt-5'>
                                <label>{langData.total_fee_you_want}</label>
                                <input id='witFee' type='number'></input>
                            </div>
                            <a onClick={()=>bankWithraw(userid)}>{langData.save}</a>
                        </div>
                    </div>
                </div>
                <div id='traHis' className='selectTra hidden'>
                    <div className='stHeader'>
                        {/*<a className='leftArrow'><img src={require('../assets/icons/leftarrow.webp')} /></a>*/}
                        <h3>{langData.all_transactions}</h3>
                    </div>
                    <div className='stFilter'>
                        <a onClick={() => changeTraType('traSuccess')} id='traSuccessS' className='stFilterActive'>{langData.success}</a>
                        <a onClick={() => changeTraType('traError')} id='traErrorS'>{langData.error}</a>
                    </div>
                    <div className='stSelect'>
                        <a onClick={() => { changeSelect('selectAll'); }} id='selectAllS' className='stSelectActive'>{langData.all}</a>
                        <a onClick={() => { changeSelect('selectSub'); }} id='selectSubS'>{langData.subscriptions}</a>
                        <a onClick={() => { changeSelect('selectMsg'); }} id='selectMsgS'>{langData.messages}</a>
                        <a onClick={() => { changeSelect('selectPos'); }} id='selectPosS'>{langData.posts}</a>
                        <a onClick={() => { changeSelect('selectTip'); }} id='selectTipS'>{langData.tips}</a>
                        <a onClick={() => { changeSelect('selectFun'); }} id='selectFunS'>{langData.funds}</a>
                        <a onClick={() => { changeSelect('selectStore'); }} id='selectStoreS'>{langData.store}</a>
                        {/*<a onClick={() => { changeSelect('selectLiv'); }} id='selectLivS'>LiveStream</a>*/}
                    </div>
                    <div id='traSuccess'>
                        <div id='selectAll' className='stBody'>
                            <div className='stTraHead'>
                                <p>{langData.description}</p>
                                <span>{langData.amount}</span>
                            </div>
                            {getData.length > 0 ? (
                                <div>
                                    {getData.map(data =>
                                        <div>
                                            {data.type !== "" ? (
                                                <div className='stTra'>
                                                    {data.desc ? (<p>{((parser.parseFromString(he.decode(data.desc), 'text/html')).documentElement.textContent)} <br /> ({data.date})</p>) : ('')}
                                                    <span>{data.price} $</span>
                                                </div>
                                            ) : ('')}
                                        </div>
                                    )}
                                </div>
                            ) : ''}
                        </div>
                        <div id='selectSub' className='stBody'>
                            <div className='stTraHead'>
                                <p>{langData.description}</p>
                                <span>{langData.amount}</span>
                            </div>
                            {getData.length > 0 ? (
                                <div>
                                    {getData.map(data =>
                                        <div>
                                            {data.type == "Subscription" ? (
                                                <div className='stTra'>
                                                    {data.desc ? (<p>{((parser.parseFromString(he.decode(data.desc), 'text/html')).documentElement.textContent)} <br /> ({data.date})</p>) : ('')}
                                                    <span>{data.price} $</span>
                                                </div>
                                            ) : ('')}
                                        </div>
                                    )}
                                </div>
                            ) : ''}
                        </div>
                        <div id='selectMsg' className='stBody'>
                            <div className='stTraHead'>
                                <p>{langData.description}</p>
                                <span>{langData.amount}</span>
                            </div>
                            {getData.length > 0 ? (
                                <div>
                                    {getData.map(data =>
                                        <div>
                                            {data.type === "Message" ? (
                                                <div className='stTra'>
                                                    {data.desc ? (<p>{((parser.parseFromString(he.decode(data.desc), 'text/html')).documentElement.textContent)} <br /> ({data.date})</p>) : ('Money Add')}
                                                    <span>{data.price} $</span>
                                                </div>
                                            ) : ('')}
                                        </div>
                                    )}
                                </div>
                            ) : ''}
                        </div>
                        <div id='selectPos' className='stBody'>
                            <div className='stTraHead'>
                                <p>{langData.description}</p>
                                <span>{langData.amount}</span>
                            </div>
                            {getData.length > 0 ? (
                                <div>
                                    {getData.map(data =>
                                        <div>
                                            {data.type === "Buy Post" ? (
                                                <div className='stTra'>
                                                    {data.desc ? (<p>{((parser.parseFromString(he.decode(data.desc), 'text/html')).documentElement.textContent)} <br /> ({data.date})</p>) : ('')}
                                                    <span>{data.price} $</span>
                                                </div>
                                            ) : ('')}
                                        </div>
                                    )}
                                </div>
                            ) : ''}
                        </div>
                        <div id='selectTip' className='stBody'>
                            <div className='stTraHead'>
                                <p>{langData.description}</p>
                                <span>{langData.amount}</span>
                            </div>
                            {getData.length > 0 ? (
                                <div>
                                    {getData.map(data =>
                                        <div>
                                            {data.type === "Tip" ? (
                                                <div className='stTra'>
                                                    {data.desc ? (<p>{((parser.parseFromString(he.decode(data.desc), 'text/html')).documentElement.textContent)} <br /> ({data.date})</p>) : ('')}
                                                    <span>{data.price} $</span>
                                                </div>
                                            ) : ('')}
                                        </div>
                                    )}
                                </div>
                            ) : ''}
                        </div>
                        <div id='selectFun' className='stBody'>
                            <div className='stTraHead'>
                                <p>{langData.description}</p>
                                <span>{langData.amount}</span>
                            </div>
                            {getData.length > 0 ? (
                                <div>
                                    {getData.map(data =>
                                        <div>
                                            {data.type === "Add Money" ? (
                                                <div className='stTra'>
                                                    {data.desc ? (<p>{((parser.parseFromString(he.decode(data.desc), 'text/html')).documentElement.textContent)} <br /> ({data.date})</p>) : ('')}
                                                    <span>{data.price} $</span>
                                                </div>
                                            ) : ('')}
                                        </div>
                                    )}
                                </div>
                            ) : ''}
                        </div>
                        <div id='selectStore' className='stBody'>
                            <div className='stTraHead'>
                                <p>{langData.description}</p>
                                <span>{langData.amount}</span>
                            </div>
                            {getData.length > 0 ? (
                                <div>
                                    {getData.map(data =>
                                        <div>
                                            {data.type === "Store" ? (
                                                <div className='stTra'>
                                                    {data.desc ? (<p>{((parser.parseFromString(he.decode(data.desc), 'text/html')).documentElement.textContent)} <br /> ({data.date})</p>) : ('')}
                                                    <span>{data.price} $</span>
                                                </div>
                                            ) : ('')}
                                        </div>
                                    )}
                                </div>
                            ) : ''}
                        </div>
                        <div id='selectLiv' className='stBody'>
                            <p>LiveStream</p>
                        </div>
                    </div>
                    <div id="traError">

                    </div>
                </div>
                <div id='traPurc' className='selectTra hidden'>
                    <div className='stHeader'>
                        <a className='leftArrow'><img src={require('../assets/icons/leftarrow.webp')} /></a>
                        <h3>{langData.purchases}</h3>
                    </div>
                    <div className='stFilter'>
                        <a onClick={() => changeTraType2('prcSuccess')} id='prcSuccessS' className='stFilterActive'>{langData.success}</a>
                        <a onClick={() => changeTraType2('prcError')} id='prcErrorS'>{langData.error}</a>
                    </div>
                    <div id="prcSuccess">
                        <div id='selectAlls' className='stBody'>
                            <div className='stTraHead'>
                                <p>{langData.description}</p>
                                <span>{langData.amount}</span>
                            </div>
                            {getData.length > 0 ? (
                                <div>
                                    {getData.map(data =>
                                        <div>
                                            {data.type === "Add Money" ? (
                                                <div className='stTra'>
                                                    {data.desc ? (<p>{((parser.parseFromString(he.decode(data.desc), 'text/html')).documentElement.textContent)} <br /> ({data.date})</p>) : ('')}
                                                    <span>{data.price} $</span>
                                                </div>
                                            ) : ('')}
                                        </div>
                                    )}
                                </div>
                            ) : ''}
                        </div>
                    </div>
                    <div id="prcError"></div>
                </div>

            </div>
        </div>
    )
}
export default Notification;